import React from 'react';
import { Helmet } from 'react-helmet';

const HomeSEO = () => {
  return (
    <Helmet>
      <title>Страшные истории и крипипасты | CreepyAI & Крипипаста.ру</title>
      <meta name="description" content="Коллекция страшных историй и крипипаст, созданных искусственным интеллектом. Новые истории каждый день." />
      <meta name="keywords" content="страшные истории, крипипасты, хоррор, ужасы, мистика, CreepyAI" />
      <meta name="author" content="CreepyAI" />
      <meta property="og:title" content="Жуткие истории | CreepyAI & Крипипаста.ру" />
      <meta property="og:description" content="Коллекция страшных историй и крипипаст, созданных искусственным интеллектом. Новые истории каждый день." />
      <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Страшные истории и крипипасты | Крипипаста.ру" />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:url" content={process.env.REACT_APP_SITE_URL} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "CreepyAI",
          "description": "Коллекция страшных историй и крипипаст, созданных искусственным интеллектом",
          "image": `${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`,
          "url": process.env.REACT_APP_SITE_URL
        })}
      </script>
    </Helmet>
  );
};

export default HomeSEO;