import { useMemo } from 'react';
import Typograf from 'typograf';

export const useTypograf = () => {
  const tp = useMemo(() => {
    const instance = new Typograf({ locale: ['ru', 'en-US'] });
    // Настройка правил типографа
    instance.enableRule('common/nbsp/afterNumber');
    instance.enableRule('ru/nbsp/afterPreposition');
    instance.enableRule('ru/nbsp/beforeParticle');
    instance.enableRule('ru/punctuation/quot');
    return instance;
  }, []);

  const processText = (text) => {
    if (!text) return '';
    return tp.execute(text);
  };

  return processText;
};