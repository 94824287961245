import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import HomeSEO from './HomeSEO';
import { formatDate } from '../utils/dateFormat';
import FlipClock from './FlipClock';
import { useTypograf } from '../hooks/useTypograf';

const LatestStory = () => {
    const [story, setStory] = useState(null);
    const [error, setError] = useState(null);
    const [tags, setTags] = useState([]);
    const [nextStoryTime, setNextStoryTime] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [tagSizes, setTagSizes] = useState([]);
    const processText = useTypograf();

    // Функция для загрузки последней истории
    const fetchLatestStory = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/stories/?ordering=-pub_date&page=1`,
                { 
                    withCredentials: true,
                    headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                 }
            );
            if (response.data && Array.isArray(response.data.results) && response.data.results.length > 0) {
                setStory(response.data.results[0]);
            } else {
                setError('Нет доступных историй');
                console.error('Нет доступных историй');
            }
        } catch (error) {
            setError(error.response?.data?.detail || 'Ошибка при загрузке последней истории');
            console.error('Ошибка при загрузке последней истории:', error);
        }
    };

    // Функция для загрузки времени следующей истории
    const fetchNextStoryTime = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/bot/next_story_time/`,
                { 
                    withCredentials: true,
                    headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                 }
            );
            const nextTime = new Date(response.data.next_story_time);
            setNextStoryTime(nextTime);
            setTimeLeft(Math.max(0, nextTime - new Date()));
        } catch (error) {
            setError(error.response?.data?.detail || 'Ошибка при загрузке времени следующей истории');
            console.error('Ошибка при загрузке времени следующей истории:', error);
        }
    };

    useEffect(() => {
        if (nextStoryTime) {
            const interval = setInterval(() => {
                const now = new Date();
                const timeDifference = nextStoryTime - now;
                setTimeLeft(Math.max(0, timeDifference));
                
                // Когда таймер достигает нуля
                if (timeDifference <= 0) {
                    // Ждем 10 секунд, чтобы дать время для публикации новой истории
                    setTimeout(async () => {
                        // Загружаем новую историю
                        await fetchLatestStory();
                        // Обновляем время следующей истории
                        await fetchNextStoryTime();
                    }, 10000);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [nextStoryTime]);

    // Выносим загрузку тегов в отдельную функцию
    const fetchTags = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/tags/cloud/`,
                { 
                    withCredentials: true,
                    headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                 }
            );
            if (Array.isArray(response.data)) {
                const sortedTags = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setTags(sortedTags);
                const maxCount = Math.max(...sortedTags.map(tag => tag.post_count));
                setTagSizes(sortedTags.map(tag => 
                    0.8 + (Math.log(tag.post_count + 1) / Math.log(maxCount + 1)) * 1.2
                ));
            } else {
                console.error('Неверный формат данных тегов');
            }
        } catch (error) {
            setError(error.response?.data?.detail || 'Ошибка при загрузке тегов');
            console.error('Ошибка при загрузке тегов:', error);
        }
    };

    // Начальная загрузка данных
    useEffect(() => {
        fetchLatestStory();
        fetchTags();
        fetchNextStoryTime();
    }, []);

    if (!story) {
        return <div className='loading-spinner'>Загрузка...</div>;
    }

    if (error) {
        return <div className="error-message">Ошибка: {error}</div>;
    }

    return (
        <div>
            <HomeSEO />
            <div>
                <h3>До следующей истории:</h3>
                <FlipClock endAt={Date.now() + timeLeft} />
            </div>
            {story && (
                <div className="story-card">
                    <h2>{processText(story.title)}</h2>
                    <div className="story-metadata">
                        <time dateTime={story.pub_date}>
                            {formatDate(story.pub_date)}
                        </time>
                    </div>
                    {story.image && (
                        <picture>
                            <source srcSet={story.image} type="image/webp" />
                            <source srcSet={story.image_jpg} type="image/jpeg" />
                            <img 
                                src={story.image_jpg} 
                                alt={story.title}
                                className="story-thumbnail"
                                loading="lazy"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/media/fallback-image.jpg';
                                }}
                            />
                        </picture>
                    )}
                    <div className="story-content">
                        {processText(story.content?.substring(0, 800) + "...")
                            .split("\n\n")
                            .map((paragraph, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
                        ))}
                    </div>
                    <Link 
                        to={`/stories/${story.slug}`} 
                        className="read-more-link"
                    >
                        Читать далее →
                    </Link>
                    <div className="story-tags">
                        {story.tags_display?.map(tag => (
                            <Link 
                                key={tag.slug} 
                                to={`/tags/${tag.slug}`}
                                className="tag-link"
                            >
                                #{tag.name}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
            <div>
                <h3>Облако тегов</h3>
                <div className="tag-cloud">
                    {tags.length > 0 ? (
                        tags.map((tag, index) => (
                            <Link 
                                key={`${tag.slug}-${index}`} 
                                to={`/tags/${tag.slug}`} 
                                style={{ fontSize: `${tagSizes[index]}em` }}
                            >
                                #{tag.name}
                            </Link>
                        ))
                    ) : (
                        <span>Нет тегов</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LatestStory;