import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import StoryLayout from './StoryLayout';
import { useLocation } from 'react-router-dom';

const RandomStory = () => {
    const [story, setStory] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const fetchRandomStory = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/stories/random/`,
                { 
                    withCredentials: true,
                    headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                 }
            );
            if (response.data) {
                setStory(response.data);
                // Прокручиваем страницу вверх при загрузке новой истории
                window.scrollTo(0, 0);
            }
        } catch (error) {
            console.error('Ошибка при загрузке случайной истории:', error);
            setError('Не удалось загрузить случайную историю');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRandomStory();
    }, [location.key, fetchRandomStory]); // Добавляем fetchRandomStory в зависимости

    if (loading) return <div className="loading-spinner">Загружается случайная история...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!story) return null;

    return (
        <div>
            <StoryLayout story={story} />
        </div>
    );
};

export default RandomStory;