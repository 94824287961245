import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { StarRating } from 'react-flexible-star-rating';
import { formatDate } from '../utils/dateFormat';
import { useTypograf } from '../hooks/useTypograf';

const StoryLayout = ({ story: initialStory, children }) => {
    const { slug } = useParams();
    const [story, setStory] = useState(initialStory);
    const [ratings, setRatings] = useState([]);
    // Здесь userRating будем определять через локальное хранилище или по ошибке сервера
    const [userRating, setUserRating] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const processText = useTypograf();

    // Загрузка истории, если не передана через props
    useEffect(() => {
        if (initialStory) {
            setStory(initialStory);
            return;
        }

        if (slug) {
            const fetchStory = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/stories/${slug}/`, {
                        withCredentials: true,
                        headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                    });
                    setStory(response.data);
                } catch (error) {
                    console.error('Ошибка при загрузке истории:', error);
                }
            };
            fetchStory();
        }
    }, [slug, initialStory]);

    // Загрузка рейтингов
    useEffect(() => {
        const fetchRatings = async () => {
            if (!story?.slug) return;

            try {
                // Добавляем параметр для избежания кеширования
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/ratings/${story.slug}/?t=${Date.now()}`, 
                    {
                        withCredentials: true,
                        headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                    }
                );
                
                const ratingsData = Array.isArray(response.data)
                    ? response.data
                    : response.data.results;
                
                setRatings(ratingsData);

                // Если вы решите использовать localStorage для сохранения информации о голосовании,
                // можно проверить его здесь:
                const localVote = localStorage.getItem(`voted_${story.slug}`);
                if (localVote) {
                    setUserRating(parseFloat(localVote));
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchRatings();
    }, [story?.slug]);

    const handleRatingChange = async (newRating) => {
        if (!story || isSubmitting) {
            setErrorMessage('Система голосования недоступна');
            return;
        }

        setIsSubmitting(true);
        try {
            const ratingData = {
                story: story.id,
                rating: newRating
            };

            // Оптимистичное обновление UI – временно отображаем голос
            // (В данном случае мы не можем знать, поставил ли пользователь голос ранее)
            setUserRating(newRating);

            await axios.post(
                `${process.env.REACT_APP_API_URL}/ratings/create/`, 
                ratingData,
                {
                    withCredentials: true,
                    headers: { 
                        Authorization: `Token ${process.env.REACT_APP_API_TOKEN}`,
                        'Content-Type': 'application/json'
                    },
                }
            );

            // Если голос успешно сохранен, обновляем рейтинги
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/ratings/${slug}/?t=${Date.now()}`,
                {
                    withCredentials: true,
                    headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                }
            );
            
            const updatedRatings = Array.isArray(response.data)
                ? response.data
                : response.data.results;
            
            setRatings(updatedRatings);
            setErrorMessage('');

            // Сохраняем факт голосования в localStorage
            localStorage.setItem(`voted_${story.slug}`, newRating);
        } catch (error) {
            // Если сервер вернул ошибку (например, "Вы уже голосовали за эту историю")
            setUserRating(null);
            let errorMsg = 'Ошибка при сохранении оценки';
            if (error.response) {
                const serverError = error.response.data;
                errorMsg = serverError.non_field_errors?.[0] 
                    || serverError.rating?.[0] 
                    || serverError.detail 
                    || errorMsg;
            }
            setErrorMessage(errorMsg);
            console.error('Ошибка голосования:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const roundToHalf = (num) => Math.round(num * 2) / 2;

    const averageRating = ratings.length > 0 
        ? roundToHalf(
            ratings.reduce((sum, r) => sum + r.rating, 0) / ratings.length
        )
        : 0;

    if (!story) return <div>Загрузка...</div>;

    return (
        <div className="story-card">
            <Helmet>
                <title>{story.title} | CreepyAI & Крипипаста.ру</title>
                <meta name="description" content={story.content.substring(0, 160)} />
                <meta name="keywords" content={story.tags_display?.map(tag => tag.name).join(', ')} />
                <meta name="author" content="CreepyAI" />
                <meta property="og:title" content={story.title + ' | CreepyAI & Крипипаста.ру'} />
                <meta property="og:description" content={story.content.substring(0, 160)} />
                <meta property="og:image" content={process.env.REACT_APP_SITE_URL + story.image_jpg} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Страшные истории и крипипасты | Крипипаста.ру" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/stories/${story.slug}`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "headline": story.title + ' | CreepyAI & Крипипаста.ру',
                        "image": process.env.REACT_APP_SITE_URL + story.image_jpg,
                        "description": story.content.substring(0, 160),
                        "datePublished": story.pub_date,
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": averageRating.toFixed(1),
                            "ratingCount": ratings.length,
                            "bestRating": "5",
                            "worstRating": "1"
                        }
                    })}
                </script>
            </Helmet>
            
            <h1>{processText(story.title)}</h1>
            <div className="story-metadata">
                <time dateTime={story.pub_date}>
                    {formatDate(story.pub_date)}
                </time>
            </div>
            
            {story.image && (
                <picture>
                    <source srcSet={story.image} type="image/webp" />
                    <source srcSet={story.image_jpg} type="image/jpeg" />
                    <img 
                        src={story.image_jpg} 
                        alt={story.title}
                        className="story-thumbnail"
                        loading="lazy"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/media/fallback-image.jpg';
                        }}
                    />
                </picture>
            )}
            
            <div className="story-content">
                {processText(story.content).split("\n\n").map((paragraph, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
            </div>

            <div className="story-tags">
                {story.tags_display?.map(tag => (
                    <Link 
                        key={tag.slug} 
                        to={`/tags/${tag.slug}`}
                        className="tag-link"
                    >
                        #{tag.name}
                    </Link>
                ))}
            </div>

            <div className="story-rating">
                <div className="rating-container">
                    <StarRating
                        starsLength={5}
                        isHalfRatingEnabled={false}
                        isHoverEnabled={!userRating && !isSubmitting}
                        isReadOnly={!!userRating || isSubmitting}
                        initialRating={userRating ?? (Number.isNaN(averageRating) ? 0 : Math.round(averageRating))}
                        dimension={10}
                        color="#FFD700"
                        onRatingChange={handleRatingChange}
                        key={userRating ?? 'default'}
                    />
                </div>
                <h5>
                    Средний рейтинг: {averageRating.toFixed(1)} ({ratings.length}&nbsp;
                    {getRatingWord(ratings.length)})
                </h5>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>

            <div className="telegram-promo">
                <h3>Понравилось? Подпишитесь на канал в Telegram.</h3>
                <p style={{ textAlign: 'center'}}>
                    В нем публикуются анонсы новых историй.
                </p>
                <a 
                    href="https://t.me/CreepyAI" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="telegram-button"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.008-1.252-.241-1.865-.44-.752-.244-1.349-.374-1.297-.789.027-.216.324-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635.099-.002.321.023.465.178.119.13.154.305.164.364.022.126.023.457.004.841z"/>
                    </svg>
                    Подписаться на Telegram
                </a>
            </div>
            
            {children}
        </div>
    );
};

const getRatingWord = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return 'оценка';
    if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) return 'оценки';
    return 'оценок';
};

export default StoryLayout;
