import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import StoriesListSEO from './StoriesListSEO';
import { StarRating } from 'react-flexible-star-rating';
import { formatDate } from '../utils/dateFormat';
import { useTypograf } from '../hooks/useTypograf';

const getRatingWord = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return 'оценка';
    if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) return 'оценки';
    return 'оценок';
};

const Archive = () => {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalStories, setTotalStories] = useState(0);
    const processText = useTypograf();

    useEffect(() => {
        const fetchStories = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/stories/?page=${currentPage}&ordering=-pub_date`,
                    { 
                        withCredentials: true,
                        headers: { Authorization: `Token ${process.env.REACT_APP_API_TOKEN}` },
                     }
                );
                
                if (response.data && Array.isArray(response.data.results)) {
                    setStories(response.data.results);
                    setTotalPages(Math.ceil(response.data.count / 10));
                    setTotalStories(response.data.count);
                } else {
                    setError('Неверный формат данных от сервера');
                }
            } catch (error) {
                setError(error.response?.data?.detail || 'Ошибка при загрузке историй');
            } finally {
                setLoading(false);
            }
        };

        fetchStories();
    }, [currentPage]);

    if (loading) return <div className="loading-spinner">Загрузка...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!stories || stories.length === 0) return <div>Историй пока нет</div>;

    return (
        <div>
            <StoriesListSEO totalStories={totalStories} />
            <h1>Архив историй</h1>
            <div className="stories-grid">
                {stories.map(story => (
                    <article key={story.id} className="story-card">
                        <h2>{processText(story.title)}</h2>
                        <div className="story-metadata">
                            <time dateTime={story.pub_date}>
                                {formatDate(story.pub_date)}
                            </time>
                        </div>
                        {story.average_rating > 0 && (
                            <div className="story-list-rating">
                                <StarRating
                                    starsLength={5}
                                    isHalfRatingEnabled={false}
                                    isReadOnly={true}
                                    initialRating={story.average_rating}
                                    dimension={15}
                                    color="#FFD700"
                                />
                                <span>({story.ratings_count} {getRatingWord(story.ratings_count)})</span>
                            </div>
                        )}
                        {story.image && (
                            <picture>
                                <source srcSet={story.image} type="image/webp" />
                                <source srcSet={story.image_jpg} type="image/jpeg" />
                                <img 
                                    src={story.image_jpg} 
                                    alt={story.title}
                                    className="story-thumbnail"
                                    loading="lazy"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/media/fallback-image.jpg';
                                    }}
                                />
                            </picture>
                        )}
                        <div className="story-content">
                            {processText(story.content?.substring(0, 200) + "...")
                                .split("\n\n")
                                .map((paragraph, index) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
                            ))}
                        </div>
                        <Link 
                            to={`/stories/${story.slug}`} 
                            className="read-more-link"
                        >
                            Читать далее →
                        </Link>
                        <div className="story-tags">
                            {story.tags_display?.map(tag => (
                                <Link 
                                    key={tag.slug} 
                                    to={`/tags/${tag.slug}`}
                                    className="tag-link"
                                >
                                    #{tag.name}
                                </Link>
                            ))}
                        </div>
                    </article>
                ))}
            </div>
            {totalPages > 1 && (
                <div className="pagination">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Archive;