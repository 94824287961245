import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion'; // Импортируем Framer Motion
import StoryDetail from './components/StoryDetail';
import TagStoryList from './components/TagStoryList';
import RandomStory from './components/RandomStory';
import LatestStory from './components/LatestStory';
import Archive from './components/Archive';
import GengarEgg from "./components/GengarEgg";
import NotFoundPage from './components/NotFoundPage';
import './App.css';
import { VERSION } from './version';

const App = () => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme;
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });
  const [renderTime, setRenderTime] = useState(0);
  const renderStartRef = useRef(performance.now());
  const [footerClicks, setFooterClicks] = useState(0);
  const [triggerGengar, setTriggerGengar] = useState(false);
  const location = useLocation(); // Получаем текущий location
  

  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      document.body.className = newTheme;
      return newTheme;
    });
  };

  // Обработка кликов по элементу с версией
  const handleFooterClick = () => {
    const newClickCount = footerClicks + 1;
    setFooterClicks(newClickCount);

    if (newClickCount === 13) {
      setTriggerGengar(true);
      setTimeout(() => {
        setTriggerGengar(false);
        setFooterClicks(0); // сброс счетчика после появления Генгара
      }, 3000);
    }
  };

  // Функция сброса внешнего триггера (передается в GengarEgg)
  const resetTrigger = () => {
    setTriggerGengar(false);
    setFooterClicks(0);
  };

  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem('theme', theme);
  }, [theme]);

  useLayoutEffect(() => {
    const renderEnd = performance.now();
    setRenderTime(renderEnd - renderStartRef.current);
  }, []);

  return (
    <div className="App">
      <Navigation theme={theme} toggleTheme={toggleTheme} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route
            path="/"
            element={
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                className="page glitch-active"
              >
                <LatestStory />
              </motion.div>
            }
          />
          <Route
            path="/archive"
            element={
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                className="page glitch-active"
              >
                <Archive />
              </motion.div>
            }
          />
          <Route
            path="/stories/:slug"
            element={
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                className="page glitch-active"
              >
                <StoryDetail />
              </motion.div>
            }
          />
          <Route
            path="/tags/:tagSlug"
            element={
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                className="page glitch-active"
              >
                <TagStoryList />
              </motion.div>
            }
          />
          <Route
            path="/random"
            element={
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.5 }}
                className="page glitch-active"
              >
                <RandomStory />
              </motion.div>
            }
          />
          <Route path="*" element={
            <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
            className="page glitch-active"
          >
            <NotFoundPage />
            </motion.div>
          }
          />
        </Routes>
      </AnimatePresence>
      <GengarEgg triggerFromFooter={triggerGengar} resetTrigger={resetTrigger} />
      <footer>
        <div className="footer-main">
          <a href="https://t.me/CreepyAI" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
              <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.09 8.91l-1.71 8.1c-.13.58-.47.72-.95.45l-2.64-1.95-1.27 1.23c-.14.14-.26.26-.53.26l.19-2.7 4.9-4.42c.21-.19-.05-.3-.33-.11l-6.06 3.82-2.6-.81c-.57-.18-.58-.57.12-.85l10.16-3.92c.47-.18.88.11.73.84z"/>
            </svg>
            Подписаться на канал в Telegram
          </a>
        </div>
        <div className="footer-disclaimer">
          <p style={{ lineHeight: "12px", textAlign: "center" }}>
            Этот сайт предоставляет уникальный контент, сгенерированный искусственным интеллектом. Здесь вы найдете крипипасты, мистические и ужасные истории, созданные с использованием нейросети.
            <br />
            Каждая история сопровождается AI-артами, сгенерированными нейросетью, чтобы усилить атмосферу ужаса. Весь контент на сайте — авторские работы ИИ, созданные специально для любителей жанра хоррор, мистики и городских легенд.
            <br />
            Материалы на этом сайте защищены авторскими правами. Использование контента разрешается только с обязательным указанием ссылки на источник. 
            <br />
            Если вы хотите узнать больше или обсудить проект, <a href="https://t.me/hypn0cat" target="_blank" rel="noopener noreferrer">пишите</a>.
            <br />
            Покемон Генгар — талисман сайта. Если вы его видели, удача может быть на вашей стороне... или он просто наблюдает за вами 👀
          </p>
        </div>
        <div className="footer-debug">
          <code onClick={handleFooterClick} style={{ cursor: "pointer" }}>
            Версия и дата сборки: <br />v{VERSION.version} ({VERSION.buildDate})
          </code>
          <br />
          <code>{renderTime.toFixed(1)} мс </code>
          <code>
            <a href="https://t.me/hypn0cat" target="_blank" rel="noopener noreferrer">
              @hypn0cat
            </a>
          </code>
        </div>
        <div className="footer-copyright">
          <p>{new Date().getFullYear()} CreepyAI & Крипипаста.ру &copy; Все права защищены.</p>
        </div>
      </footer>
    </div>
  );
};

// Компонент навигации
const Navigation = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  
  const handleRandomStory = () => {
    if (window.location.pathname === '/random') {
      window.location.reload();
    } else {
      navigate('/random');
    }
  };

  return (
    <nav className="nav-menu">
      <div className="nav-menu-container">
        <Link to="/" className="nav-link">
          <span className="nav-icon"></span>
          <span className="nav-text">{process.env.REACT_APP_SITE_DOMAIN}</span>
        </Link>
        <Link to="/archive" className="nav-link">
          <span className="nav-icon"></span>
          <span className="nav-text">Архив</span>
        </Link>
        <button onClick={handleRandomStory} className="nav-link random-button">
          <span className="nav-icon">🎲</span>
          <span className="nav-text">Случайная история</span>
        </button>
      </div>
      <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
    </nav>
  );
};

// Компонент переключателя темы
const ThemeToggle = ({ theme, toggleTheme }) => {
  return (
    <button 
      className="theme-toggle" 
      onClick={toggleTheme}
      aria-label={theme === 'light' ? 'Включить темную тему' : 'Включить светлую тему'}
    >
      {theme === 'light' ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" viewBox="0 0 24 24" stroke="black">
          <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
        </svg>              
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24" stroke="currentColor">
          <circle cx="12" cy="12" r="5" strokeWidth="2" />
          <line x1="12" y1="1" x2="12" y2="3" strokeWidth="2" strokeLinecap="round"/>
          <line x1="12" y1="21" x2="12" y2="23" strokeWidth="2" strokeLinecap="round"/>
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" strokeWidth="2" strokeLinecap="round"/>
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" strokeWidth="2" strokeLinecap="round"/>
          <line x1="1" y1="12" x2="3" y2="12" strokeWidth="2" strokeLinecap="round"/>
          <line x1="21" y1="12" x2="23" y2="12" strokeWidth="2" strokeLinecap="round"/>
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" strokeWidth="2" strokeLinecap="round"/>
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      )}
    </button>
  );
};

// Обертка для использования useLocation
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
};