import React from 'react';
import { Helmet } from 'react-helmet';

const TagSEO = ({ tag }) => {
  return (
    <Helmet>
      <title>Истории с тегом #{tag.name} | CreepyAI & Крипипаста.ру</title>
      <meta name="description" content={`Страшные истории и крипипасты с тегом #${tag.name}. ${tag.count} историй в коллекции.`} />
      <meta name="keywords" content={`истории, крипипасты, страшные истории, хоррор, ужасы, мистика, CreepyAI, ${tag.name}`} />
      <meta name="author" content="CreepyAI" />
      <meta property="og:title" content={`Истории с тегом #${tag.name} | CreepyAI & Крипипаста.ру`} />
      <meta property="og:description" content={`Страшные истории и крипипасты с тегом #${tag.name}. ${tag.count} историй в коллекции.`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Страшные истории и крипипасты | Крипипаста.ру" />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`} />
      <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/tags/${tag.slug}`} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": `Истории с тегом #${tag.name}`,
          "description": `Страшные истории и крипипасты с тегом #${tag.name}`,
          "image": `${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`,
          "url": `${process.env.REACT_APP_SITE_URL}/tags/${tag.slug}`,
          "numberOfItems": tag.count
        })}
      </script>
    </Helmet>
  );
};

export default TagSEO;