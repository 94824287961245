import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  const [hovered, setHovered] = useState(false);

  // Эффект для управления классом body
  useEffect(() => {
    if (hovered) {
      document.body.classList.add('glitch-active');
    } else {
      document.body.classList.remove('glitch-active');
    }

    // Очистка при размонтировании компонента
    return () => {
      document.body.classList.remove('glitch-active');
    };
  }, [hovered]);

  const restartGif = () => {
    const timestamp = new Date().getTime();
    return `/egg/gengar404.gif?t=${timestamp}`;
  };

  return (
    <div className="not-found-container">
        <Helmet>
        <meta name="robots" content="noindex, follow" />
        <title>404 - Страница не найдена</title>
      </Helmet>
      <h1>404 - Страница не найдена</h1>
      <div 
        className="gengar-wrapper"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img 
          src={hovered ? restartGif() : '/egg/gengar404.png'} 
          alt="Gengar Egg" 
          className="gengar-image"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.3s ease',
            transform: hovered ? 'scale(1.05)' : 'scale(1)'
          }}
        />
      </div>
      <p style={{ textAlign: 'center' }}>Кажется, вы потерялись...</p>
      <div style={{ textAlign: 'center' }}>
        <Link className="read-more-link" to="/">Вернуться на главную</Link>
      </div>
    </div>
  );
}