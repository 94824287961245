import { useState, useEffect, useCallback, useRef } from "react";

const GengarEgg = ({ triggerFromFooter, resetTrigger }) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ top: "50%", left: "50%" });
  const [imgKey, setImgKey] = useState(0);
  const timerRef = useRef(null);
  const glitchTimerRef = useRef(null); // Отдельный таймер для глитча
  const isActiveRef = useRef(false);
  const [imgSize, setImgSize] = useState({ 
    width: 150,
    height: 150 
  });

  // Получаем реальные размеры изображения
  useEffect(() => {
    const img = new Image();
    img.src = "/egg/gengar.gif";
    img.onload = () => {
      setImgSize({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };
  }, []);

  const getRandomPosition = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    
    const maxX = viewportWidth - imgSize.width;
    const maxY = viewportHeight - imgSize.height;
    
    return {
      left: Math.max(0, Math.min(maxX, Math.random() * maxX)) + scrollLeft,
      top: Math.max(0, Math.min(maxY, Math.random() * maxY)) + scrollTop
    };
  }, [imgSize]);

  const triggerGengar = useCallback(() => {
    if (isActiveRef.current) return;
    isActiveRef.current = true;

    // Обновляем позицию с учетом текущего скролла
    const newPos = getRandomPosition();
    setPosition({
      top: `${newPos.top}px`,
      left: `${newPos.left}px`,
      transform: 'translate(-50%, -50%)'
    });

    // Сбрасываем предыдущие таймеры
    if (timerRef.current) clearTimeout(timerRef.current);
    if (glitchTimerRef.current) clearTimeout(glitchTimerRef.current);

    // 1. Начало глитч-эффекта
    document.body.classList.add("glitch-active");

    // 2. Обновление ключа изображения
    setImgKey(Date.now());

    // 3. Показ Генгара через 100 мс после начала глитча
    const gengarTimer = setTimeout(() => {
      setShow(true);
    }, 100);

    // 4. Выключение глитча через 3.5 секунды
    glitchTimerRef.current = setTimeout(() => {
      document.body.classList.remove("glitch-active");
    }, 3500);

    // 5. Полное скрытие через 6 секунд
    timerRef.current = setTimeout(() => {
      setShow(false);
      isActiveRef.current = false;
      resetTrigger?.();
    }, 6000);

    return () => {
      clearTimeout(gengarTimer);
    };
  }, [getRandomPosition, resetTrigger]);

  useEffect(() => {
    const handleResize = () => {
      if (isActiveRef.current) {
        const newPos = getRandomPosition();
        setPosition({
          top: `${newPos.top}px`,
          left: `${newPos.left}px`
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getRandomPosition]);

  useEffect(() => {
    // Триггер для случайного появления
    const interval = setInterval(() => {
      if (Math.random() < 0.05) triggerGengar();
    }, 60000); // Проверяем веротность появления Генгара каждые 60 секунд
    
    return () => clearInterval(interval);
  }, [triggerGengar]);

  useEffect(() => {
    // Триггер из футера
    if (triggerFromFooter) triggerGengar();
  }, [triggerFromFooter, triggerGengar]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(glitchTimerRef.current);
      document.body.classList.remove('glitch-active');
    };
  }, []);

  return (
    <>
      {show && (
        <img
          key={imgKey}
          src={`/egg/gengar.gif?time=${imgKey}`}
          alt="Gengar"
          className="gengar-image"
          style={{
            position: "fixed",
            top: position.top,
            left: position.left,
            width: 'auto',
            height: '150px',
            maxWidth: '90vw',
            zIndex: 1000,
            pointerEvents: "none",
            animation: "gengar-appear 0.5s ease-out"
          }}
        />
      )}
    </>
  );
};

export default GengarEgg;