import React from 'react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const FlipTimer = ({ endAt }) => {
  return (
    <FlipClockCountdown
      to={endAt}
      labels={['ДНИ', 'ЧАСЫ', 'МИНУТЫ', 'СЕКУНДЫ']}
      labelStyle={{
        fontSize: '10px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: 'var(--text)',
      }}
      digitBlockStyle={{
        width: 40,
        height: 60,
        fontSize: '4rem',
        backgroundColor: 'var(--background-light)',
        color: 'black',
        fontFamily: 'Monospace, sans-serif',
      }}
      separatorStyle={{
        color: 'var(--primary-color)',
        size: '4px'
      }}
      dividerStyle={{
        height: 1,
        backgroundColor: 'var(--background-light)',
      }}
      showSeparators={false}
      duration={0.5}
      renderMap={[false, true, true, true]}
      className="flip-clock-theme"
    >
      <div className='loading-spinner'>Новая история скоро появится...</div>
    </FlipClockCountdown>
  );
};

export default FlipTimer;