import React from 'react';
import { Helmet } from 'react-helmet';

const StoriesListSEO = ({ totalStories }) => {
  return (
    <Helmet>
      <title>Все страшные истории | CreepyAI & Крипипаста.ру</title>
      <meta name="description" content={`Архив всех страшных историй и крипипаст. ${totalStories} жутких историй, созданных искусственным интеллектом.`} />
      <meta name="keywords" content="страшные истории, крипипасты, хоррор, ужасы, мистика, CreepyAI" />
      <meta name="author" content="CreepyAI" />
      <meta property="og:title" content="Все страшные истории | CreepyAI & Крипипаста.ру" />
      <meta property="og:description" content={`Архив всех страшных историй и крипипаст. ${totalStories} жутких историй, созданных искусственным интеллектом.`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Страшные истории и крипипасты | Крипипаста.ру" />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`} />
      <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/stories`} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": "Архив страшных историй",
          "description": "Полная коллекция страшных историй и крипипаст",
          "image": `${process.env.REACT_APP_SITE_URL}/web-app-manifest-512x512.png`,
          "url": `${process.env.REACT_APP_SITE_URL}/stories`,
          "numberOfItems": totalStories
        })}
      </script>
    </Helmet>
  );
};

export default StoriesListSEO;